.button-tabs {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    color: #475569 !important;
    font-weight: 600;
    width: fit-content;
    padding: 16px 0 8px;
    margin: 0px;
    transition: border-bottom 0.3s ease, color 0.3s ease;
    cursor: pointer;
    line-height: 22px;

    &:hover {
        border-bottom: 2px solid #5497FF;
        color: #5497FF !important;
    }

    &.active {
        border-bottom: 2px solid #5497FF;
        color: #5497FF !important;
    }

    &:focus {
        border-bottom: 2px solid #5497FF;
        color: #5497FF !important;
    }
}

.custom-card {
    &:hover {
        background-color: #F6F0FF;

        .button-card {
            background-color: #FFFFFF !important;
        }
    }
}

.video-custom-card {
    width: 800px;
    max-width: 800px;
    &:hover {
        background-color: #F6F0FF;

        .button-card {
            background-color: #FFFFFF !important;
        }
    }
}

.container-css {
    font-family: "Nunito-Regular" !important;
}

.Carousel-root-31 {
    width: 90% !important;
    max-width: unset !important;
    position: static !important;
}

.Carousel-root-31 .CarouselItem > div {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.Carousel-root-31 div:nth-child(4) {
    position: absolute;
    left: 0;
    bottom: 20px;
}

.Carousel-root-31 .videoBox {
    max-height: 90vh !important;
    min-height: 90vh !important;
}

.carousel-btn {
    display: flex !important;
    flex-direction: row !important;
    right: 0 !important;
    width: fit-content !important;
    gap: 30px !important;
}

.CarouselItemResourse {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;

    button {
        border-radius: 100%;
        height: 48px;
        width: 48px;
    }
}

.CarouselDocItemResourse{
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    div {
        height:90%
    }

    button {
        border-radius: 100%;
        height: 48px;
        width: 48px;
    }
}

.custom-image-card {
    flex-direction: column;
    align-items: center;
    text-align: left;
    max-width: 800px;
    width: 800px
}

.modalTextContainer {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    flex-direction: row !important;
}

.card-content-carousel {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.carousel-btn {
    top: 2rem;
    left: auto !important;
    margin-right: 3rem;
    width: 100px;
}

.bg-black {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.377);
    position: fixed;
    top: 0px;
    left: 0px;
}

.Carousel-root-159 {
    top: 10rem !important;
    padding: 0 15rem !important;
}

.carousel-btn-video {
    top: unset !important;
    bottom: 26rem !important;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    right: 8%;
}

.custom-card {
    width: 100% !important;
}

.icon-lang-container {
    width: 100%;
}

.uiResoursesContainer {
    gap: 0 !important;
    grid-gap: 0;
}

@media screen and (min-width: 768px) {
    .cardContent {
        width: 95% !important;
    }

}


@media screen and (max-width: 766px) {

    .custom-image-card {
        width: 350px !important;
    }

    .video-custom-card {
        width: 320px !important;
    }

    .cardContent {
        width: 95% !important;
    }

    .carousel-btn {
        top: 25rem;
    }

}


@media screen and (max-width: 853px) {
    .custom-card {
        width: 280px;
    }

    .custom-image-card .video-custom-card  {
        width: 400px !important;
    }

    .carousel-btn {
        top: 3rem;
    }

    .cardContent {
        width: 95% !important;
    }
}

@media screen and (max-width: 870px) {
    .custom-image-card .video-custom-card  {
        width: 400px !important;
    }

    .carousel-btn {
        margin-right: -1rem;
    }

    .cardContent {
        width: 95% !important;
    }

    .CarouselItemResourse {
        button {
            margin-left: 2rem;
            margin-right: 2rem;
        }
    }

}

@media screen and (max-width:930px) {
    .custom-image-card {
        max-width: 400px !important;
    }

    .video-custom-card {
        max-width: 400px !important;
    }

    .cardContent {
        width: 95% !important;
    }
}

@media screen and (max-width:1260px) {
    .custom-image-card {
        max-width: 600px !important;
    }

    .video-custom-card {
        max-width: 600px !important;
    }

    .cardContent {
        width: 80% !important;
    }
}



@media screen and (min-width: 960px) {

    .cardContent {
        width: 95% !important;
        min-width: 98% !important;

        .card-content-carousel {
            min-width: inherit !important;
        }
    }
}

@media screen and (max-width:600px) {
    .video-player {
        position: relative;
        .player-controls {
            position: absolute;
            left: 42% !important;
        }
    }
    .custom-image-card  {
        max-width: 300px !important;
    }

    .video-custom-card  {
        max-width: 300px !important;
    }
}

@media screen and (max-width: 450px) {
    .carousel-btn {
        top: 3rem;
    }
}

@media screen and (max-width: 400px) {
    .CarouselItemResourse {
        .Carousel-prev-29 {
            left: -28px
        }
        .Carousel-next-28 {
            right: -28px
        }
    }
    .video-custom-card  {
        max-width: 250px !important;
    }
}

@media screen and (min-width: 872px) {
    .carousel-btn {
        margin-right: 0rem;
    }
}

@media screen and (max-width: 960px) {
    .video-player {
        position: relative;
        .player-controls {
            position: absolute;
            left: 45% !important;
        }
    }
    .cardContent {
        width: 95% !important;
        min-width: 98% !important;

        .card-content-carousel {
            min-width: inherit !important;
        }
    }

    .productContainer {
        margin-top: 22px;
    }
}

@media screen and (min-width: 962px) {

    .productContainer {
        margin-top: 130px;
    }

    .CarouselItemResourse {
        button {
            margin-left: 5rem;
            margin-right: 5rem;
        }
    }

    .carousel-btn {
        top: 2rem;
        margin-right: 3rem;
    }

}

.tabs-container::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.video-player {
    position: relative;
    border-radius: 16px;
    .player-controls {
        position: absolute;
        left: 47%;
        bottom: 47%;
        display: flex;
        align-items: center;
    
    
        img {
          width: 50px !important;
          height: 50px !important;
        }
    }
}

.no_data_found {
    height: 320px !important;
    width: 360px !important;

    @media (max-width: 400px) {
        height: 224px !important;
        width: 252px !important;
    }
}

.no_data_found_text {
    font-family: "Nunito-Regular" !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 26px !important;
    color: #475569 !important;
    text-align: center !important;
    margin-top: -200px !important;

    @media (max-width: 400px) {
        font-size: 16px !important;
        line-height: 20px !important;
    }
}

.no_data_found_text_list {
    font-family: "Nunito-Regular" !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 26px !important;
    color: #475569 !important;
    text-align: center !important;

    @media (max-width: 400px) {
        font-size: 16px !important;
        line-height: 20px !important;
    }
}

.catalog-language-filter {
    .MuiAutocomplete-inputRoot {
        display: block;
        input {
            width: 100%;
        }
    }
}