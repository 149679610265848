// User Profile Basic Start
.main_section_about_me {
  border-radius: 8px !important;
  gap: 20px !important;
  padding: 2px 20px 24px 20px !important;
}

// User Profile Basic End

// Recent Activities Start
.conatiner_recent_main {
  border-radius: 8px !important;
  padding: 20px !important;
  gap: 16px !important;
  background-color: #ffffff !important;
}

.list_main{
   width: 100% !important;
   background-color: 'background.paper' !important;
   margin-bottom: -16px !important;
}

.hrs_cls {
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #64748B !important;
}

.HorizIcon_cls {
  display: inline !important;
  margin-left: 5px !important;
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 18px !important;
  color: #475569 !important;
}

.HorizIcon_cls_clicked {
  display: inline !important;
  margin-left: 5px !important;
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 18px !important;
  color: #475569 !important;
  background: #F5F5F5 !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}

.button_first {
  font-family: "Comfortaa-Bold" !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  line-height: 11.15px !important;
  color: #ffffff !important;
}

.button_sec {
  font-family: "Comfortaa-Bold" !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  line-height: 11.15px !important;
  color: #ffffff !important;
}

.text_btn_health {
  border: none !important;
  border-radius: 40px !important;
  background-color: #5497ff !important;
  color: white !important;
  padding: 5px 12px 5px 12px !important;
  margin-left: 2px !important;
}

.text_Subtitle1 {
  display: inline !important;
  font-family: "Comfortaa-Bold" !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  color: #475569 !important;
}

.text_Subtitle2 {
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: #475569 !important;
  margin-Top: 10px !important;
  height: auto !important;
  max-width: 600px !important;
  width: 100% !important;
}

.text_Subtitle3 {
  display: inline !important;
  font-family: "Comfortaa-Bold" !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  color: #475569 !important;
}

.text_Subtitle4 {
  font-family: 'Nunito-Regular' !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #475569 !important;
  margin-right: 8px !important;
}

.text_week {
  font-family: 'Nunito-Regular' !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #475569 !important;
}

.text_reply {
  font-family: 'Nunito-Regular' !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #64748B !important;
}

.divider_cls {
  padding: 0px 16px !important;
  margin: 10px 15px !important;
  height: 3px !important;
  color: #CBD5E1 !important;
}

.text_liked {
  font-family: 'Nunito-Regular' !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #64748B !important;
  margin-left: 5px !important;
}

.text_comment {
  font-family: 'Nunito-Regular' !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #64748B !important;
}

.num_comment_text {
  font-family: 'Nunito-Regular' !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #475569 !important;
}

.num_like_text {
  font-family: 'Nunito-Regular' !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #475569 !important;
  margin-left: 5px !important;
}

.text_share {
  font-family: 'Nunito-Regular' !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: #64748B !important;
}

.image {
  display: flex !important;
  border-radius: 50px !important;
  margin-top: 25px !important;
  padding-right: 0px !important;
  height: 280px !important;
  width: 100% !important;
  max-width: 444px !important;
}

.text_first_para{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}


.text_Recent_Subtitle1 {
  display: inline !important;
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: #475569 !important;
}

.Author_img_main {
  width: 40px !important;
  height: 40px !important;
}



// Recent Activities End

// user groups Component Start css



.top-box-container {
  display: flex !important;
  justify-content: space-between !important;
  padding-top: 8px !important;
  .MuiTabs-indicator {
    background-color: #5496FF !important;
  }
  @media (max-width: 450px) {
    padding-top: 0px !important;
  }
}

.PrivateTabIndicator-colorSecondary-11 {
  background-color: #5496FF !important;
}

.group_container{
  width: '100%' !important;
  border: none !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.individual_group_container{
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 24px!important;
  padding-right: 24px!important;

  @media (max-width: 450px) {
    padding-left: 12px!important;
    padding-right: 12px!important;
  }
}

.tab_top_container{
  margin-left: 24px !important;

  .MuiTabs-scroller{
    height: 43px !important;
  }
  .MuiTab-root{
    min-width: 0px !important;
  }
  @media (max-width: 450px) {
    margin-left: 0px !important;
  }
}

.pending_requests_chip {
  background-color: #5497FF !important;
  color: #FFFFFF !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-family: "Nunito-Regular" !important;
  height: 24px !important;
  margin-top: auto !important;
  margin-right: 12px !important;
  .MuiChip-label {
      white-space: normal !important;
  }
  @media (max-width: 450px) {
    display: none !important;
  }

}

.pending_requests_icon{
  margin: 0px 12px 0px 0px !important;
  @media (min-width: 450px) {
    display: none !important;
  }
}

.group_menu_items {
  position: absolute;
  right: 5%;
  z-index: 9;
  top: 30px;
  display: flex;
  padding: 12px 12px;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03);
}

.group_option_text {
  color: #475569 !important;
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  margin: 0px 0px 0px 8px !important;
}

.text_tab_first.Mui-selected {
  color: #5497FF !important;
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  height: 20px !important;
}

.text_tab_second.Mui-selected {
  color: #5497FF !important;
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
}

.text_tab_first {
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 22px !important;
  color: #475569 !important;
  text-transform: none !important;
}

.text_tab_second {
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 22px !important;
  color: #475569 !important;
  text-transform: none !important;
}

.pending-count_container{
  justify-content: center;
  align-items: center;
  background: #F87171;
  border-radius: 50px;
  display: flex !important;
  position: absolute !important;
  margin: 0px 0px 16px 12px !important;
  padding: 2px 4px !important;
  @media (min-width: 450px) {
    display: none !important;
  }
}

.pending_count_number {
  font-family: "Nunito-Regular" !important;
  font-size: 7px !important;
  font-weight: 500 !important;
  line-height: 9.55px !important;
  color: #ffffff !important;
  padding-top: 1px !important;

}

.text_member {
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  color: #475569 !important;
  display: flex !important;
  align-items: center !important;
  margin-right: 8px !important;

  @media (max-width: 450px) {
    display: none !important;
  }
}

.plus-create-icon{
  @media (min-width: 350px) {
    display: none !important;
  }
}

.grp-people-icon{
  @media (min-width: 450px) {
    display: none !important;
    margin-right: 2px !important;
  }
}

.Horizicon_cls {
  color: #64748B !important;
  cursor: pointer;
}

.text_btn_create {
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #5497FF !important;
  padding: 0px 0px 0px 0px !important;

  @media (max-width: 350px) {
    display: none !important;
  }
}


.search_create_container{
  display: flex !important;
  align-items: center !important;
  margin-right: 24px !important;
  justify-content: flex-end !important;

  @media (max-width: 450px) {
    margin-right: 8px !important;
  }
}

.create_search_icon{
  color: #475569 !important;
  height: 24px !important;
  width: 24px !important;
  margin-right: 16px !important;

  @media (max-width: 450px) {
    margin-right: 8px !important;
  }

  @media (max-width: 350px) {
    margin-right: 0px !important;
  }
}

.text_first_group {
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: #475569 !important;
  cursor: pointer !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  display: block !important;
  max-width: 350px !important;

  @media(max-width: 450px){
    max-width: 250px !important;
  }

  @media(max-width: 400px){
    max-width: 200px !important;
  }

  @media(max-width: 350px){
    max-width: 100px !important;
  }
}

.group_photo_container{
  margin: 0px 12px 0px 0px !important;
  width: 48px !important;
  height: 48px !important;
  cursor: pointer !important;
  min-width: 48px !important;
}

.group_photo_main_container{
  width: 48px !important;
  height: 48px !important;
}

.text_third_group {
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: #64748B !important;
  cursor: pointer !important;
  margin-left: 6px !important;
  // overflow: hidden !important;
  // white-space: nowrap !important;
  // text-overflow: ellipsis !important;
  // display: block !important;
  // max-width: 400px !important;

  // @media(max-width: 450px){
  //   max-width: 200px !important;
  // }

  @media(max-width: 400px){
    display: none !important;
  }

  @media(max-width: 350px){
    display: none !important;
  }
}

.text_second_group {
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: #475569 !important;
  cursor: pointer !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  display: block !important;
  max-width: 400px !important;

  @media(max-width: 450px){
    max-width: 200px !important;
  }

  @media(max-width: 400px){
    max-width: 150px !important;
  }

  @media(max-width: 350px){
    max-width: 100px !important;
  }
}

// End user groups Component css


// User About Me Css Start
.text_first_tb
{
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #475569 !important;
  padding-left: 0px !important;
  vertical-align: text-top !important;
  word-break: break-word !important;
  @media(max-width: 600px){
    font-size: 12px !important;
  }
}

.location1_text_align
{
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #475569 !important;
  padding-left: 0px !important;
  vertical-align: text-top !important;
  padding-bottom: 8px !important;
  @media(max-width: 600px){
    font-size: 12px !important;
  }
}

.location2_text_align
{
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #475569 !important;
  padding-left: 0px !important;
  vertical-align: text-top !important;

  @media(max-width: 600px){
    font-size: 12px !important;
  }
}

.summary_exp_grid
{
  padding-top: 24px;
  padding-left: 0px;
  padding-right: 0px;
  @media(max-width: 600px){
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}

.summary_exp_grid_two
{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;
  @media(max-width: 600px){
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}

.text_first_tb_grid{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;

  @media(max-width: 600px){
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}

.text_second_tb_grid{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;
  justify-content: flex-end !important;
  @media(max-width: 600px){
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}

.skils_second_tb_grid{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;
  @media(max-width: 600px){
    padding-top: 0px !important;
    padding-bottom: 12px !important;
  }
}

.view_skills_chips {
  margin: 0px 8px 8px 0px !important;
  background-color: #5497FF !important;
  color: #FFFFFF !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  font-family: "Nunito-Regular" !important;
  word-break: break-all !important;
  text-overflow: none !important;
  height: auto !important;
  max-height: 45px !important;
  min-height: 28px !important;
  .MuiChip-label {
      white-space: normal !important;
      @media (max-width: 450px){
          font-size: 12px !important;
          line-height: 14px !important;
          word-break: break-all !important;
          text-overflow: none !important;
      }
  }
  @media (max-width: 450px){
      padding: 6px 3px !important;
      height: auto !important;
      max-height: 45px !important;
  }

}

.view_show_less{
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  color: #5497FF !important;
  padding: 0px !important;
  margin: 0px 8px 8px 0px !important
}

.affilation_text{
  font-family: "Nunito-Regular" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: #475569 !important;
  word-wrap: break-word;
  
  @media(max-width: 600px){
    font-size: 12px !important;
  }
  p{
    margin: 0 !important;
    margin-bottom: 16px !important;
  }
}

.location_first_value{
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: right;
}

.summary_experience_title
{
  font-family: "Nunito-Regular" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: #475569 !important;
  margin-bottom: 8px !important;
  margin-left: 8px !important;
}

.show_less_text
{
  font-family: "Comfortaa-Bold" !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
  color: #5497FF !important;
  padding-left: 0px !important;
  margin-left: 8px !important
}

.text_second_tb
{
  font-family: "Nunito-Regular" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  color: #475569 !important;
  padding-right: 0px !important;

}

.text_button_main{
  font-family: "Nunito-Regular" !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  color: #475569 !important;
  padding-right: 0px !important;
  padding: 5px 12px 5px 10px !important;
  border-radius: 40px !important;
  color: white !important;
  background-color: #5497FF !important;
 margin: 5px 0px 5px 0px !important;
  
}

// User About Me Css End


// User Upload Photo Css Start
.text_header_title{
  font-family: "Comfortaa-Bold" !important;
  font-size: 16px !important; 
  font-weight: 700 !important;
  line-height: 24px !important;                       
                      
}
.col_parent{
  display: flex !important;
  padding: 40px !important;
  background-color: white !important;
  margin: 0 auto !important;
  max-width: 457px !important;
  width: 100% !important;
  flex-wrap: wrap !important;
  justify-content:space-between !important;
}
.col_child{

  width: 180px !important;
  height: 120px !important;
  border-radius: 8px !important;
  border: 1px solid #CBD5E1 !important;
  padding: 20px 0px 20px 0px !important;
  gap: 16px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  background-color: #F1F5F9 !important;
  color: #F1F5F9 !important;
  flex: 0 1 auto !important;
  box-sizing: 'border-box' !important;

}
/* boxwraper */
.boxwraper {
  position: relative;
  display: flex;
  margin: auto;
  flex-direction: row;
  align-items: center;
  width: 457px;
  height: 120px;
  justify-content: space-between;
}
.or-box {
  position: absolute;
  font-size: 14px !important;
  font-weight: 700;
  font-family: "Comfortaa-Bold" !important;
  right: 45%;
  top: 50%;
  color: #64748B;
}

/* header */
.header {
  background-color: #5497FF;
  text-align: start;
  font-size: 16px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 60px;
  line-height: 24px;
  display: flex;
  align-items: center;
  font-family: "Comfortaa-Medium";
  h6 {
    padding: 8px 24px 8px 24px !important;
    color: #FFFFFF !important;
    font-family: "Comfortaa-Bold" !important;
    font-weight: 700 !important;
    font-size: 16px !important;

  }
}
.secondboxwraper {
  // height: 26rem;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  scrollbar-width: none;
max-height: 384px !important;
align-items: baseline;
gap:12px;
max-height: 384px;
position: fixed;

}
.secondboxwraper::-webkit-scrollbar{
  display: none !important; 
}
.galleryModel {
  width: 384px;
  margin: auto;
  margin-block: 51px !important;
  height: 362px;


}

.galleryModel::-webkit-scrollbar{
  display: none;
}

.user-profile-conatiner {
    display: flex;
    width: 100%;
    background: linear-gradient(179.98deg, #E8EFFC 0.01%, #F7F9FC 99.99%);
    min-height: 518px;
    padding-bottom: 45px;
    padding-inline: 24px;
    box-sizing: border-box;
}

.banner-section {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 93px;
  font-size: 10px;
  @media(max-width: 960px) {
    margin-top: 0px;
  }
}

.profile_user_new{
  margin-left: auto;
  margin-right: auto;
  padding-top: 130px;
  max-width: 1400px;
  justify-content: center;
  display: flex;
  padding-left: 16px; 
  padding-right: 16px; 
  @media (max-width: 960px) {
    margin-top: 0px !important;
    padding-top: 16px !important;
  }
}

.edit_profile_button{
  margin-top: 10px;
    color: #475569;
    font-size: 14px;
    text-align: center;
    font-family:  "Nunito-Regular" !important;
    font-weight: 400;
    line-height: 22;
    cursor: pointer;
    margin-right:  8px !important;
    @media (max-width: 660px) {
      display: none;
    }
}

.show_border{
  @media (min-width: 660px) {
    display: none;
  }
}

.user_about_me_container{
  margin-top: 40px !important;
  @media (max-width: 660px) {
    margin-top: 16px !important;
  }
}

.banner-image {
  width: 100%;
  height: 340px;
  object-fit: cover;
  border-radius: 0px 0px 12px 12px;
}
.hover-edit-cover {
  position: absolute;
  right: 25px;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-top: 46px;
  height: 32px !important;
  width: 148px !important;
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
#cover-hover-edit-cover {
}
/* uploadABox */
.uploadABox {
  background-color:#F1F5F9;
  border: solid 1px;
  width: 180px;
  height: 120px;
  border-radius: 8px;
  flex-direction: column;
  display: flex;
  border: 1px solid #CBD5E1;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 20px 0px 20px 0px;
  h6 {
    width: 180px  !important;
    height: 44px !important;
  }
  img {
    width: 28px;
    height: 28px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .input-hide-image {
      margin-top: 10px;
      width: 150px;
      position: absolute;
      height: 113px;
      top: 0px;
      opacity: 0;
  }

  h6 {
    margin-top: 10px;
    color: #64748B;
    font-size: 14px;
    text-align: center;
    font-family:  "Comfortaa-Medium";
    font-weight: 700;
  }
}

/* modalStyle */
.modalStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 558px;
  height: 260px;
  background-color:#FFFFFF;
  border: 0px solid #000;
  box-shadow: 24px;
  border-radius: 8px;
  box-sizing: border-box;
}
.tag-interest {
  
}

.secondmodalStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:#FFFFFF;
  border: 0px solid #000;
  box-shadow: 24px;
  width: 486px;
  max-height: 584px;
  border-radius: 8px;
}
.imgbox {
  margin-bottom: 10px;
}
.edit-profile-grey {
  color: #64748B;
}
.cover-edit {
  width: 30px !important;
  height: 30px !important;
}
.edit-profile {
  .hover-edit-profile {
    display: block !important;
}
  color: #94A3B8;
  height: 16px !important;
  width: 16px !important;
  
}
.upload-text-profile {
  font-family:  "Comfortaa-Medium";
font-weight : 700;
size : 10px;
line-height: 11.5px;
}
.hover-edit-profile {
    position: absolute !important;
    display: flex !important;
    align-items: center !important;
    background-color: #fff !important;
    padding: 8px !important;
    margin-top: 3px !important;
    border-radius: 4px !important;
    margin-left: 55px !important;
    width: 148px !important;
    height: 32px !important;
    box-sizing: border-box;
    display: flex;
justify-content: space-between;
  // display: none !important;
}
.profile-photo {
  width: 16px !important;
  height: 16px !important;
}

.edit-button-text {
  color: #475569;
  font-size: 10px !important;
  font-family: 'Comfortaa-Medium' !important;
  font-weight: 700;
  padding-left: 5px !important;
  // padding-right: 10px;
}
.about-me-container {
  td {
  font-family: 'Comfortaa-Bold' !important;
  font-weight: 700 !important;
  font-size: 12px !important;
}

}

.edit-icon-button {
  top: 10px !important;
  position: absolute !important; 
  background-color: #ffffff !important;
  right: 10px !important;
  border-radius: 20px !important;
  height: 30px !important;
  width: 30px !important;
}
.profile-details {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 76px;
}
// .mobile-view {
//   margin-top: 3px !important;
//   border-radius: 6px !important;
//   margin-left: 45px !important;
//   width: 115px !important;
// }







.tickamark {
  display: none !important;
  position: absolute !important;
  top: -10% !important;
  width: 24px !important;
  height: 24px !important;
  right: -14% !important;
width: 40px !important;
height: 40px !important;
z-index: 999999;
}
.selected-image {
  .tickamark {
    display: block !important;
  }
}
.gallery-image {
  position: relative;
  border-radius: 5px;
  width: 120px !important;
  height: 120px !important;
  border-radius: 5px;
  padding: 2px;
  box-sizing: border-box;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: 'cover';
    border-radius: 5px;
  }
}
.upload-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 8px 24px 7px 24px;
}
// User Upload Photo Css End
.upload-footer {
  background-color: #F1F5F9 !important;
  height: 60px !important;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.profile_user_image {
  z-index: 1;
  width: 120px !important;
  height: 120px !important;

  img {
    z-index: 0;
  }
}

.profile_user_section {
  z-index: 0 !important;
}

@media (max-width: 960px) {
  .user-profile-conatiner {
    padding-inline: 0px !important;
  }
    .mobile-view {
      display: block;
    }
    .header-section {
      display: none;
      z-index: 99;
    }
    .text_tab_first {
      font-size: 12px !important;
    }
    .text_tab_second {
      font-size: 12px !important;
    }
    .text_btn_create {
      font-size: 12px !important;
      width: 41% !important;
    }
    .text_first_group {
      font-size: 12px !important;

    }
    .continer-profile-padd {
      padding: 0px !important;
    }
    .hover-edit-profile { 
width: 148px !important;
margin-left: 45px !important;
    }
    .modalStyle {
      .header {
        font-size: 16px !important;
      }
      h6 { font-size: 12px !important; }
      width: 345px !important;
      .boxwraper {
        width: 305px !important;
      }
      .uploadABox {
        width: 128px !important;
        padding-inline: 10px;
        h6 {
          width: 112px !important;
        }
        img {
          width: 28px !important;
          width: 28px !important;
        }

      }
    }
    .secondmodalStyle {
      width: 345px !important;
      max-height: 468px !important;
      .galleryModel {
        width: 300px !important;
        margin: auto !important;
        margin-block: 51px !important;
        max-height: 300px !important;
margin-top: 24px !important;
margin-bottom: 24px !important;
      }
      h6 {
        font-size: 16px !important;
      }
      .secondboxwraper {
        font-size: 12px;
        width: 300px !important;
        height: 300 !important;
        max-height: 300px !important;
        position: fixed;
      }
        .gallery-image {
          width: 92px !important;
          height: 92px !important;
      }
    }
  }
  @media (min-width: 961px) {
    .mobile-view {
      display: none;
    }
    .header-section {
      display: block;
      z-index: 99;
    }
    
  }

  @media (max-width: 390px) {
    .hover-edit-profile {
      margin-left: 30px !important;
      margin-top: 10px !important;
    }
  }

  .recentCardTitle {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.recentText {
    color: var(--neutrals-cool-gray-600, #475569);
    font-family: "Comfortaa-Bold" !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 26px;
}

.user-recentPostCard {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: var(--basic-white, #FFF);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    padding: 20px 0;
}

.user-recentPostBox {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    padding-inline: 24px;
}

.user-descriptionBox {
    display: flex;
    width: 85%;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.user-desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.user-descTitle {
    color: var(--neutrals-cool-gray-600, #475569);
    text-align: center;
    font-family: "Comfortaa-Regular" !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.user-descText {
    color: var(--neutrals-cool-gray-600, #475569);
    font-family: "Nunito-Regular" !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 22px !important;
}

.user-postImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 24px;
}

.singlePostImageContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.user-likesCard {
  display: flex;
  width: 93%;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding-left: 24px;

  @media(max-width: 899px) {
      width: 88%;
  }
}

.user-likesCommentsContainer {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.user-likesCommentsButtons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.user-commentDetail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.user-lineDivider {
  width: 93%;
  background: #cbd5e1;
  margin: auto !important;
}

.userProfileText {
  color: var(--neutrals-cool-gray-600, #475569);
  font-family: "Comfortaa-Regular" !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 22px !important;
}
.file-name-conatiner {
  color: #475569;
    font-family: 'Nunito-Regular';
    font-size: 14px;
    font-weight: 700;
    padding: 8px 14px;
    line-height: 28px;
    align-items: center;
    text-align: justify;
}

.hide {
  opacity: 0 !important;
}

.myDIV:hover + .hide {
  opacity: 1 !important;
}

.grp-kabab {
  top: 66% !important;
  right: 16px !important;
}

.grp_info{
  display: flex;
  gap: 5px;
  align-items: center;

  @media (max-width: 450px) {
    gap: 0px !important;
  }
}

.modal-al-cls { 
  top: 55% !important;
  right: 0% !important;
}

.carouselRoot {
  position: absolute !important;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 15%;
  width: 800px;
  overflow: visible !important;

  @media(max-width: 960px) {
    width: 75%;
    max-width: 75%;
  }

  .videoBox {
      max-height: 200px;
      min-height: 200px;
  }

  .Carousel-indicators-125 {
      @media(max-width: 767px) {
          margin-top: 0;
      }
  }
}

.carouselRoot img {
  width: 100%;
  display: block;
  height: 500px;
  max-height: 500px;
  min-height: 500px;
  object-fit: contain;

  @media(max-width: 767px) {
      width: 100% !important;
  }
}

.carouselRoot>div:nth-child(2) {
  right: -80px !important;

  button {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    margin: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
      }
  }

  svg {
      width: 2rem;
      height: 2rem;
  }

  @media(max-width: 767px) {
      right: -40px !important;
  }
}

.carouselRoot>div:nth-child(3) {
  left: -80px !important;

  button {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    margin: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
      }
  }

  svg {
      width: 2rem;
      height: 2rem;
  }

  @media(max-width: 767px) {
      width: 20px;
      left: -40px !important;
  }
}

.carousel-btn {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 120px;
  left: 0;
  right: 0;
  top: 55%;
  margin: 0 auto;
  align-items: center;
  gap: 60px;

  @media(max-width: 767px) {
      height: 65px;
  }

}

.carouselVideoRoot {
  margin: 0 auto;
  top: 15%;
  width: 800px;
  position: absolute !important;
  left: 0;
  right: 0;
  overflow: visible !important;

  @media(max-width: 960px) {
    max-width: 75%;
    width: 75%;
  }
  .Carousel-indicators-125 {
    @media(max-width: 767px) {
        margin-top: 0;
    }
  }

  .videoBox {
      min-height: 200px;
      max-height: 200px;
  }

  
}

.carouselAudioRoot {
  margin: 0 auto;
  top: 45%;
  width: 800px;
  position: absolute !important;
  left: 0;
  right: 0;
  overflow: visible !important;

  @media(max-width: 960px) {
    max-width: 75%;
    width: 75%;
  }
  .Carousel-indicators-125 {
    @media(max-width: 767px) {
        margin-top: 0;
    }
  }

  .videoBox {
      min-height: 200px;
      max-height: 200px;
  }
}

.carouselVideoRoot>div:nth-child(2) {
  right: -80px !important;
  svg {
    width: 2rem;
    height: 2rem;
  }

  @media(max-width: 767px) {
      right: -40px !important;
  }

  button {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    margin: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
      }
  }
}

.carouselVideoRoot>div:nth-child(3) {
  left: -80px !important;
  svg {
    width: 2rem;
    height: 2rem;
}

@media(max-width: 767px) {
    width: 20px;
    left: -40px !important;
}

  button {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    margin: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
      }
  }
}

.carouselAudioRoot>div:nth-child(2) {
  right: -80px !important;
  svg {
    width: 2rem;
    height: 2rem;
  }

  @media(max-width: 767px) {
      right: -40px !important;
  }

  button {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    margin: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
      }
  }
}

.carouselAudioRoot>div:nth-child(3) {
  left: -80px !important;
  svg {
    width: 2rem;
    height: 2rem;
}

@media(max-width: 767px) {
    width: 20px;
    left: -40px !important;
}

  button {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    margin: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
      }
  }
}

.carouselDocRoot {
  position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media(max-width: 600px) {
      // width: 70%;
      // margin: 0 auto
    }

    button {
        border-radius: 100%;
        height: 48px;
        width: 48px;
    }
}

.carouselDocRoot>div:nth-child(2) {
  svg {
    width: 2rem;
    height: 2rem;
  }

  @media(max-width: 767px) {
      right: 40px !important;
  }

  @media(max-width: 600px) {
    right: 5px !important;
  }

  button {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    margin: 0 8rem;
    @media(max-width: 1200px) {
      margin: 0 5rem;
    }
    @media(max-width: 767px) {
      margin: 0 3rem;
    }
    @media(max-width: 600px) {
      margin: 0;
    }

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
      }
  }
}

.carouselDocRoot>div:nth-child(3) {
  svg {
    width: 2rem;
    height: 2rem;
}

  @media(max-width: 767px) {
      left: 40px !important;
  }

  @media(max-width: 600px) {
    width: 20px;
    left: 5px !important;
  }

  button {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    margin: 0 8rem;
    @media(max-width: 1200px) {
      margin: 0 5rem;
    }
    @media(max-width: 767px) {
      margin: 0 3rem;
    }
    @media(max-width: 600px) {
      margin: 0;
    }

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
      }
  }
}

.true_action_click {
  max-height: 44px !important;
  background-color: #5497FF !important;
  width: 100% !important;
}

.false_action_click {
  max-height: 44px !important;
  background-color: #FFFFFF !important;
  width: 100% !important;
  border-radius: 8px !important;
  border-width: 2px !important;
  border-color: #5497FF !important;
}

.true_false_action_cont {
  justify-content: space-between !important;
  width: 100% !important;
  display: flex !important;
  background: #F8FAFC !important;
  border-radius: 16px !important;
}

.true_false_action_box {
  padding: 16px !important;
  background: #FFFFFF !important;
  @media(max-width: 450px) {
      width: 85% !important;
  }
}

.icon_text_cont{
  display: grid !important;
  justify-content: center !important;
  margin: 12px 0px 24px !important;
}

.grp_warning_text{
  font-family: "Nunito-Regular" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: #475569 !important;
  text-align: center !important;
}

.leave-grp-icon{
  width: 74px !important;
  height: 74px !important;
}